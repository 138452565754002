import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import AtelierCollectionCard from "./AtelierCollectionCard";

class AperoCeramiqueCollectionRoll extends React.Component {

    render() {
        const { data } = this.props

        const { edges: collections } = data.allShopifyCollection

        return (
          <div className="columns is-multiline">
              {collections &&
              collections.map(({ node: collection }) => {
                  if (collection.products.length > 0) {
                      if((collection.title.toLowerCase().includes("apero") || collection.title.toLowerCase().includes("apéro")) && collection.title.toLowerCase().includes("peinture")) {
                          return (
                            <AtelierCollectionCard collection={collection}/>
                          )
                      }
                    }
                }
              )}
          </div>
        )
    }
}

AperoCeramiqueCollectionRoll.propTypes = {
    data: PropTypes.shape({
        allShopifyCollection: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
    type: PropTypes.string,
}

export default () => (
  <StaticQuery
    query={graphql`
      query AtelierCollectionRollQuery {
        allShopifyCollection {
          edges {
              node {
                id
                title
                description
                descriptionHtml
                productsCount
                products {
                  id
                  title
                  shopifyId
                  handle
                  priceRangeV2 {
                    maxVariantPrice {
                      amount
                    }
                    minVariantPrice {
                      amount
                    }
                  }
                  featuredImage {
                    gatsbyImageData
                    src
                    altText
                  }
                }
                image {
                  gatsbyImageData
                  altText
                  src
                }
              }
            }
        }
      }
    `}
    render={(data, type, count) => <AperoCeramiqueCollectionRoll data={data} type={type} count={count} />}
  />
)
