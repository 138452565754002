import React from "react";
import Layout from '../../components/layout/Layout'
import PreviewCompatibleImage from '../../components/image/PreviewCompatibleImage'
import { useIsMobile } from "../../hooks/useIsMobile";
import CeramiqueBannerWithChildren from "../../components/ceramique/CeramiqueBannerWithChildren";

import AperoCeramiqueCollectionRoll from "../../components/atelier/AperoCeramiqueCollectionRoll";


const imageInfo1 = {
  "alt": "Des réalisations d'enfants lors d'ateliers a forma",
  "image": "/img/v2/Menfants-min.JPG",
  "maxWidth": 250
}

const imageInfo2 = {
  "alt": "Une enfant en pleine création ...",
  "image": "/img/v2/Nenfantspeinture-min.JPG",
  "maxWidth": 250
}

const AtelierEnfantsPage = () => {

  React.useEffect(() => {
  }, [])


  const {isMobile } = useIsMobile()

  return (
    <Layout>
      <section className="section">
        <div className="container">

          <div className="content">
            <div className="columns">
              <div className="column is-12">
                <div className="columns has-text-justified" style={{ textAlign: 'justify' }}>

                  <div className="column is-6">
                    <h3 className="title is-4">Déposez-nous vos enfants pour des mercredis créatifs !</h3>
                    <p></p>
                    <p>Le mercredi de 15h30 à 16h30, soit pour de la peinture sur céramique (3 mercredis sur 4), soit pour du modelage (1 mercredi sur 4). Dès 6 ans, sur réservation.</p>
                  </div>

                  <div className="column has-text-centered is-3 is-justify-content-center is-flex">
                    <PreviewCompatibleImage imageInfo={imageInfo2} />
                  </div>

                  <div className="column has-text-centered is-3 is-justify-content-center is-flex">
                    <PreviewCompatibleImage imageInfo={imageInfo1} />
                  </div>
                </div>
              </div>
            </div>

            <div className="content is-offset-1" style={{marginTop: '1rem', marginBottom: '1rem'}}>

              <AperoCeramiqueCollectionRoll/>
              <CeramiqueBannerWithChildren/>
            </div>
          </div>
        </div>
      </section>
    </Layout >
  )
}

export default AtelierEnfantsPage


