import React from "react";


const CeramiqueBannerWithChildren = () => {
	return (
		<div className="gift-card-section" style={{ color: "black", backgroundColor: "#eed7c5", borderRadius: 15, border: "1 px solid grey" }}>
			<p>Vous pouvez aussi réserver un créneau pour faire de la peinture sur céramique avec votre enfant <a className="has-text-terracota link" style={{ color: "#993e30" }} href="/cafe-ceramique">ici
				! </a></p>
		</div>
	)
}

export default CeramiqueBannerWithChildren